import { useLocation } from 'react-router-dom';
import { Nav, NavLink } from "./styled";

const links = [
    {
        to: '/about',
        inner: 'About',
    },
    {
        to: '/plan',
        inner: 'Plan',
    },
    {
        to: '/help',
        inner: 'Help',
    },
    {
        to: '/terms',
        inner: 'Terms of use',
    },
    {
        to: '/privacy',
        inner: 'Privacy Policy',
    },
]

const HeaderNav = (props) => {
    const pathname = useLocation().pathname;
    return (
        <Nav>
            {links.map((link) => {
                return (
                    <NavLink isActive={pathname === link.to ? true : false} to={link.to} inner={link.inner} />
                )
            })}
        </Nav>
    )
}

export default HeaderNav;