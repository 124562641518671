import { Header, HeaderContent, HeaderTitle, ImageLink, TitleLink, Image } from "./styled"
import logo from "resource/logo/logo.png";
import HeaderNav from "./HeaderNav";

const HeaderComponent = (props) => {
    return (
        <Header>
            <HeaderContent>
                <HeaderTitle>
                    <ImageLink to='/' inner={<Image src={logo} alt='logo' />} />
                    <TitleLink to='/' inner={
                        <>
                            <span>Owl</span>
                            <span>Blocker</span>
                        </>
                    } />
                </HeaderTitle>
                <HeaderNav />
            </HeaderContent>
        </Header>
    )
}

export default HeaderComponent;