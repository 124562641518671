import HomePage from 'components/ui/Main/HomePage';
import PrivacyPage from 'components/ui/Main/PrivacyPage';
import TermsPage from 'components/ui/Main/TermsPage';
import { lazy } from 'react'

const Routes = [
    {
      path: '/*',
      component: HomePage,
      exact: true,
    },
    {
      path: '/terms',
      component: TermsPage,
      exact: true,
    },
    {
      path: '/privacy',
      component: PrivacyPage,
      exact: true,
    },
    // {
    //   path: '/login',
    //   component: Login,
    //   exact: true,
  
    // },
    // {
    //   path: '/registration',
    //   component: Registration,
    //   exact: true,
  
    // },
    // {
    //   path: '*',
    //   component: MainContent,
    //   exact: true,
    // },
  ]
  
  export default Routes;