const { PrivacyContent, PrivacyTitle, PrivacyText } = require('./styled');

const PrivacyPage = (props) => {
  return (
    <PrivacyContent>
      <PrivacyTitle>Privacy Policy</PrivacyTitle>
      <PrivacyText>
        Last updated: 2023-01-13
        <br />
        <br />
        In the following policy, OwlBlocker refers to the service offered by
        WEBSITE CREATORS UK LTD (the "company", “we” or "us") through the
        software provided through it (the "Service"). This Privacy Policy
        explains what information we collect through your access and use of our
        Service, the use we make of such information, and the security level we
        provide for protecting such information. By using the Service, you agree
        to the terms outlined in this Privacy Policy.
        <br />
        <br />
        Information Collection and Use
        <br />
        <br />
        WEBSITE CREATORS UK LTD is committed to protecting and respecting your
        privacy. It is our overriding policy to collect as little user
        information as possible to ensure a private and anonymous user
        experience when using the Service. Below is a summary of the way we deal
        with information when you use the Service. For a better experience,
        while using our Service, we may require you to provide us with certain
        personally identifiable information, including but not limited to No
        personal data collection.
        <br />
        <br />
        The app does use third-party services that may collect information used
        to identify you.
        <br />
        <br />
        Link to the privacy policy of third-party service providers used by the
        app
        <br />
        <br />
        <a href="https://firebase.google.com/policies/analytics">
          Google Analytics for Firebase
        </a>
        <br />
        <br />
        Log Data
        <br />
        <br />
        We do not collect personal info of users, uses your email address only
        to respond to your communications on the official website or in form in
        mobile application but we can't identify users with email that was sent
        to our contact address. We do not know which user was connected to the
        app and any data of connection. We do not know user IP. We do not keep
        users data. We do not save users data.
        <br />
        <br />
        It is important to note that it does not collect browsing history,
        traffic data, or DNS queries that could be used to identify any specific
        user.
        <br />
        <br />
        We collect information regarding the total sum of data transferred by
        all users of the app per country.
        <br />
        <br />
        We collect statistics to maintain our quality of service. We may know
        total statistics per regions and per servers but we can't identify this
        data with random users. We don't save and don't keep any data that can
        be uniquely identified with a specific user.
        <br />
        <br />
        Cookies
        <br />
        <br />
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
        <br />
        <br />
        This Service does not use these “cookies” explicitly. However, the app
        may use third-party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
        <br />
        <br />
        Security
        <br />
        <br />
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
        <br />
        <br />
        Links to Other Sites
        <br />
        <br />
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
        <br />
        <br />
        Children’s Privacy
        <br />
        <br />
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If you are a parent or guardian and you are aware that
        your child has provided us with personal data, please contact us. If we
        become aware that we have collected personal data from anyone under the
        age of 13 without verification of parental consent, we take steps to
        remove that information from our servers. If we need to rely on consent
        as a legal basis for processing your information and your country
        requires consent from a parent, We may require your parent's consent
        before we collect and use that information.
        <br />
        <br />
        Changes to This Privacy Policy
        <br />
        <br />
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
        <br />
        <br />
        This policy is effective as of 2023-01-13
        <br />
        <br />
        Contact Us
        <br />
        <br />
        If you have any questions about this Privacy Policy, you can contact us
        by email:{' '}
        <a class="mail" href="mailto:support@owlblocker.com">
          support@owlblocker.com
        </a>
      </PrivacyText>
    </PrivacyContent>
  );
};

export default PrivacyPage;
