//import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  BrowserRouter
} from 'react-router-dom';

import RenderRoutes from 'components/RenderRoutes/RenderRoutes';
import routes from 'routes/routes';
import HeaderComponent from 'components/ui/Header/HeaderComponent';
import FooterComponent from 'components/ui/Footer/FooterComponent';

function App() {
  return (
    <BrowserRouter>
      <HeaderComponent />
      <Routes>
        {
          routes.map((router) => {
            return(
              <Route
                key={router.path}
                path={router.path}
                element={<RenderRoutes {...router} />}
              />
            );
          })
        }
      </Routes>
      <FooterComponent />
    </BrowserRouter>
  );
}

export default App;
