import styled from 'styled-components';
import RouteLink from "elements/RouteLink/RouteLink";

export const MainBlock = styled.main`
    margin: auto;
    width: 80%; 
`
export const FirstBlock = styled.div`
    display: flex;
    margin-top: 60px;
    margin-bottom: 80px;
    align-items: center;
    justify-content: center;
`
export const Image = styled.img`
    max-height: ${ (props) => { return props.height + 'px' } };
    margin-top: ${ (props) => { return props.marginTop + 'px' } };
    margin-right: ${ (props) => { return props.marginRight + 'px' } };
    margin-left: ${ (props) => { return props.marginLeft + 'px' } };
    margin-bottom: ${ (props) => { return props.marginBottom + 'px' } };
`
export const H1 = styled.h1`
    margin: 0;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    width: ${ (props) => { return props.width + 'px' } };
`
export const P = styled.p`
    margin: 0;
    margin-bottom: ${ (props) => { return props.marginBottom + 'px' } };
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: ${ (props) => { return props.textAlign } };
    width: ${ (props) => { return props.width + 'px' } };
    color: rgba(0, 0, 0, 0.8);
`
export const Button = styled.button`
    border: 1px solid #5F76D3;
    border-radius: 16px;
    background: #6880E2;
    box-shadow: 0px 1px 0px #5F76D3;
    font-size: 16px;
    color: white;
    width: 270px;
    height: 52px;

    &:hover {
        background: white;
        color: black;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }
`
export const SecondBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
`
export const H2 = styled.h2`
    margin: 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    text-align: ${ (props) => { return props.textAlign } };
    width: ${ (props) => { return props.width + 'px' } };
    margin-top: ${ (props) => { return props.marginTop + 'px' } };
    margin-right: ${ (props) => { return props.marginRight + 'px' } };
    margin-left: ${ (props) => { return props.marginLeft + 'px' } };
    margin-bottom: ${ (props) => { return props.marginBottom + 'px' } };
`
export const ItemsSecondBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
`
export const ItemSecondBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
        margin-right: 88px;
    }
`
export const H3 = styled.h3`
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    margin-top: ${ (props) => { return props.marginTop + 'px' } };
    margin-right: ${ (props) => { return props.marginRight + 'px' } };
    margin-left: ${ (props) => { return props.marginLeft + 'px' } };
    margin-bottom: ${ (props) => { return props.marginBottom + 'px' } };
`
export const ThirdBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
`
export const ContentThird = styled.div`
    display: flex;
    justify-content: center;    
    align-items: center;
    flex-direction: ${(props) => props.isReverse ? 'row-reverse' : 'row'};
    &:not(:last-child) {
        margin-bottom: 60px;
    }
`
export const FourthBlock = styled.div`
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const LeftSlider = styled.div`
    margin-right: 250px;
    width: 376px;
`
export const SliderContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 0px #D1D8F5;
    border-radius: 16px;
    background: rgba(228, 231, 243, 0.9);
    width: 376px;
    height: 432px;
`
export const P_Slider = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: rgba(33, 33, 33, 0.9);
    margin-top: ${ (props) => { return props.marginTop + 'px' } };
    margin-right: ${ (props) => { return props.marginRight + 'px' } };
    margin-left: ${ (props) => { return props.marginLeft + 'px' } };
    margin-bottom: ${ (props) => { return props.marginBottom + 'px' } };
`
export const List = styled.ul`
    margin: 0;
    margin-bottom: 44px;
    list-style-position: outside;
    list-style: none;

    & > li::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        clip-path: circle();
        background-color: #6880E1;
        margin-left: -3.5em;
        width: 2em;
    }
`
export const ListItem = styled.li`
    font-weight: 400;
    font-size: 14px;
    color: rgba(33, 33, 33, 0.5);
    
    &:not(:last-child){
        margin-bottom: 15px;
    }
`
export const PriceText = styled.span`
    color: #6880E1;
    font-size: 32px;
    margin-bottom: 16px;
    & > span:last-child {
        font-size: 18px;
    }
`
export const TrialText = styled.p`
    margin: 0;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(33, 33, 33, 0.5);
`
export const RightArrowButton = styled.div`
    position: absolute;
    z-index: 1;
    cursor: pointer;
    right: -50px;
    top: 45%;
`
export const LeftArrowButton = styled.div`
    position: absolute;
    z-index: 1;
    cursor: pointer;
    left: -50px;
    top: 45%;
`
export const FifthBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
`
export const ContactUsBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 72px;
    background: rgba(228, 231, 243, 0.3);
    border: 1px solid rgba(209, 216, 245, 0.4);
    box-shadow: 0px 2px 0px #D1D8F5;
    border-radius: 16px;
    width: 500px;
`
export const FormContactUs = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
`
export const InputContactUs = styled.input`
    background: rgba(225, 230, 249, 0.6);
    border: none;
    border-radius: 16px;
    width: 420px;
    padding-left: 22px;

    &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        opacity: 0.6;
        position: absolute;
        top: 20px;
    }
`
export const InputName = styled(InputContactUs)`
    height: 55px;
    margin-bottom: 20px;
`
export const InputEmai = styled(InputContactUs)`
    height: 55px;
    margin-bottom: 20px;
`
export const InputMessage = styled.textarea`
    height: 150px;
    width: 420px;
    margin-bottom: 32px;
    background: rgba(225, 230, 249, 0.6);
    border-radius: 16px;
    border: none;
    padding-left: 22px;
    padding-top: 20px;
    resize: none;

    &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        opacity: 0.6;
    }
`
export const ButtonContactUs = styled.button`
    border: 1px solid #5F76D3;
    border-radius: 16px;
    background: #6880E2;
    box-shadow: 0px 1px 0px #5F76D3;
    font-size: 16px;
    color: white;
    width: 420px;
    height: 52px;

    &:hover {
        background: white;
        color: black;
    }
`

////////////////////////////////////////////////////////////////////

export const PrivacyContent = styled.main`
    margin: auto;
    width: 80%;
`
export const PrivacyTitle = styled.h1`
    text-align: start;
    margin-top: 60px;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
`
export const PrivacyText = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 0;
    margin-bottom: 100px;
`

///////////////////////////////////////////////////////////////////////

export const TermsContent = styled.main`
    margin: auto;
    width: 80%;
`
export const TermsTitle = styled.h1`
    text-align: start;
    margin-top: 60px;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
`
export const TermsText = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 0;
    margin-bottom: 100px;
`