import styled from 'styled-components';
import RouteLink from 'elements/RouteLink/RouteLink';

export const Footer = styled.footer`
  display: flex;
  background-color: #e1e6f9;
  width: 100%;
  height: 195px;
  border: 1px solid #f2f2f2;
`;
export const FooterContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin: auto;
  height: 100%;
  width: 75%;

  @media (max-width: 1284px) {
    width: 85%;
  }
  @media (max-width: 1050px) {
    width: 95%;
  }

  @media (max-width: 850px) {
    width: 100%;
    justify-content: space-between;
  }
`;
export const FooterTitle = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;
export const ImageLink = styled(RouteLink)`
  margin-right: 10px;
`;
export const Image = styled.img`
  max-height: 80px;
`;
export const TitleLink = styled(RouteLink)`
  font-size: 28px;
  font-weight: 500;
  text-decoration: none;
  color: black;

  & > span:first-child {
    font-weight: 700;
  }
`;
export const AllLinks = styled.div`
  display: flex;
  margin-top: 30px;
  width: 20%;
  justify-content: space-between;
  height: fit-content;
`;
export const ColumnLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Link = styled(RouteLink)`
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:hover {
    color: #6880e2;
  }

  ${(props) => {
    const isActive = props.isActive;
    let style = `
            color: black;
        `;
    if (isActive) {
      style = `
                color: #6880E2;
            `;
    }

    return style;
  }}
`;
export const Copyright = styled.div`
  margin-top: 30px;
  color: black;
  opacity: 0.54;
  font-size: 18px;
`;
