import { Copyright, Footer, FooterContent, FooterTitle, Image, ImageLink, TitleLink } from "./styled";
import logo from "resource/logo/logo.png";
import FooterLinks from "./FooterLinks";

const FooterComponent = (props) => {
    return (
        <Footer>
            <FooterContent>
                <FooterTitle>
                    <ImageLink to='/' inner={<Image src={logo} alt='logo' />} />
                    <TitleLink to='/' inner={
                        <>
                            <span>Owl</span>
                            <span>Blocker</span>
                        </>
                    } />
                </FooterTitle>
                <FooterLinks />
                <Copyright>
                    OwlBlocker© 2022
                </Copyright>
            </FooterContent>
        </Footer>
    )
}

export default FooterComponent;