import styled from 'styled-components';
import RouteLink from "elements/RouteLink/RouteLink";

export const Header = styled.header`
    width: 100%;
    height: 80px;
    background-color: #F9F9F9;
    border: 1px solid #F2F2F2;
`

export const HeaderContent = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    width: 75%;

    @media(max-width: 1284px) {
        width: 85%;
    }
    @media(max-width: 1050px) {
        width: 95%;
    }

    @media(max-width: 850px) {
        width: 100%;
        justify-content: space-between;
    }
`
export const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    width: fit-content;
`

export const ImageLink = styled(RouteLink)`
    margin-right: 10px;
`
export const Image = styled.img`
    max-height: 80px;
`

export const TitleLink = styled(RouteLink)`
    font-size: 28px;
    font-weight: 500;
    text-decoration: none;
    color: black;

    & > span:first-child {
        font-weight: 700;
    }
`
export const Nav = styled.div`
    display: flex;
    justify-content: space-around;
    width: 40%;

    @media(max-width: 1284px) {
        width: 45%;
    }
    @media(max-width: 1050px) {
        width: 50%;
    }
    @media(max-width: 850px) {
        width: 55%;
    }
`
export const NavLink = styled(RouteLink)`
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;

    &:hover{
        color: #6880E2;
    }

    ${(props) => {
        const isActive = props.isActive;
        let style = `
            color: black;
        `;
        if (isActive) {
            style = `
                color: #6880E2;
            `
        }

        return style;
    }}
`