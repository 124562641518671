import { useLocation } from 'react-router-dom';
import { AllLinks, ColumnLinks, Link } from "./styled";

const leftLinks = [
    {
        to: '/about',
        inner: 'About',
    },
    {
        to: '/plan',
        inner: 'Plan',
    },
    {
        to: '/help',
        inner: 'Help',
    }
]
const rightLinks = [
    {
        to: '/terms',
        inner: 'Terms of use',
    },
    {
        to: '/privacy',
        inner: 'Privacy Policy',
    },
]

const FooterLinks = (props) => {
    const pathname = useLocation().pathname;
    return(
        <AllLinks>
            <ColumnLinks>
                {leftLinks.map((link) => {
                    return (
                        <Link isActive={pathname === link.to ? true : false} to={link.to} inner={link.inner} />
                    )
                })}
            </ColumnLinks>
            <ColumnLinks>
                {rightLinks.map((link) => {
                    return (
                        <Link isActive={pathname === link.to ? true : false} to={link.to} inner={link.inner} />
                    )
                })}
            </ColumnLinks>
        </AllLinks>
    )
}

export default FooterLinks;