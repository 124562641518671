import Slider from 'react-slick';
import React from 'react';

import {
  FirstBlock,
  MainBlock,
  Image,
  H1,
  H2,
  P,
  Button,
  SecondBlock,
  ItemsSecondBlock,
  H3,
  ItemSecondBlock,
  ThirdBlock,
  ContentThird,
  FourthBlock,
  LeftSlider,
  SliderContent,
  P_Slider,
  List,
  ListItem,
  PriceText,
  TrialText,
  RightArrowButton,
  LeftArrowButton,
  FifthBlock,
  ContactUsBlock,
  FormContactUs,
  InputName,
  InputEmai,
  InputMessage,
  ButtonContactUs,
} from './styled';
import image1 from 'resource/images/image1.png';
import image2 from 'resource/images/image2.png';
import image3 from 'resource/images/image3.png';
import image4 from 'resource/images/image4.png';
import image5 from 'resource/images/image5.png';
import image6 from 'resource/images/image6.png';

import screen1 from 'resource/images/screen1.png';
import screen2 from 'resource/images/screen2.png';

import arrowLeft from 'resource/images/arrow-left.png';
import arrowRight from 'resource/images/arrow-right.png';

const NextArror = (props) => {
  const { className, style, onClick } = props;
  return (
    <RightArrowButton onClick={onClick}>
      <img height={32} src={arrowRight} alt="next arrow" />
    </RightArrowButton>
  );
};
const PrevArror = (props) => {
  const { className, style, onClick } = props;
  return (
    <LeftArrowButton onClick={onClick}>
      <img height={32} src={arrowLeft} alt="next arrow" />
    </LeftArrowButton>
  );
};

const CustomSlide = (data) => {
  const { props } = data;
  return (
    <SliderContent {...props} className="sliderContent">
      <H3 marginBottom={20} marginTop={10}>
        Premiun Plan
      </H3>
      <P_Slider marginBottom={24}>Best price for all</P_Slider>
      <List>
        <ListItem>Block Scripts and Socials feature.</ListItem>
        <ListItem>Block Adult Sites Feature.</ListItem>
        <ListItem>Block cookies and Bets feature.</ListItem>
        <ListItem>Block Images and Fonts feature.</ListItem>
      </List>
      <PriceText>
        <span>$3.99</span>
        <span>/week</span>
      </PriceText>
      <TrialText>After 3 days trial, Autorenewable</TrialText>
      <Button>Activate</Button>
    </SliderContent>
  );
};

const HomePage = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArror />,
    prevArrow: <PrevArror />,
  };

  return (
    <MainBlock>
      <FirstBlock>
        <Image height={413} marginRight={120} src={image1} alt="first image" />
        <div>
          <H1 width={603}>Use the Internet without annoying advertising.</H1>

          <P marginBottom={40} width={522}>
            Say goodbye to annoying banners and video ads while browsing the
            web. With OwlBlocker you’ll be able to enjoy a faster and smoother
            browsing experience.
          </P>
          <div>
            <Button>Get the app</Button>
            <Button>Try for free</Button>
          </div>
        </div>
      </FirstBlock>
      <SecondBlock>
        <H2 width={603} marginBottom={60} textAlign="center">
          Your personal digital invisibility with OwlBlocker
        </H2>
        <ItemsSecondBlock>
          <ItemSecondBlock>
            <Image
              height={247}
              marginBottom={40}
              src={image2}
              alt={'second image'}
            />
            <H3 marginBottom={30}>Script Blocker</H3>
            <P textAlign="center" width={336}>
              The smart application will provide an extra security for your
              browsing experience. It can control any Scripts and plugins
              provided on a website.
            </P>
          </ItemSecondBlock>
          <ItemSecondBlock>
            <Image
              height={297}
              marginBottom={40}
              src={image3}
              alt={'third image'}
            />
            <H3 marginBottom={30}>AdBlocker</H3>
            <P textAlign="center" width={364}>
              Get cleaner, faster internet access and block annoying ads with
              the most trustworthy technology available. Create black and white
              lists and decide waht ads should be blocked.
            </P>
          </ItemSecondBlock>
          <ItemSecondBlock>
            <Image
              height={247}
              marginBottom={40}
              src={image4}
              alt={'fourth image'}
            />
            <H3 marginBottom={30}>Adult Content Blocker</H3>
            <P textAlign="center" width={336}>
              OwlBlocker will help you to stay focussed on what's important.
              Free yourself from porn ads distraction, annoying gambling ads,
              and any social media pop-ups.
            </P>
          </ItemSecondBlock>
        </ItemsSecondBlock>
        <Button>All Features</Button>
      </SecondBlock>
      <ThirdBlock>
        <ContentThird>
          <Image height={397} marginRight={235} src={screen1} alt="screen1" />
          <div>
            <H2 width={603} marginBottom={40}>
              Not all ads are bad. Decide yourself what to block
            </H2>
            <P width={509}>
              OwlBlocker also offers a customizable whitelist feature, allowing
              you to choose which websites and services you want to support by
              allowing ads to be displayed.
            </P>
          </div>
        </ContentThird>
        <ContentThird isReverse={true}>
          <Image height={419} marginLeft={140} src={screen2} alt="screen1" />
          <div>
            <H2 width={603} marginBottom={40}>
              There is no reason to put up with annoying Ads
            </H2>
            <P width={509}>
              The app uses advanced technology to block all types of ads,
              including banners, pop-ups, and video ads, as well as tracking
              scripts that slow down your browsing experience.
            </P>
          </div>
        </ContentThird>
      </ThirdBlock>
      <FourthBlock>
        <LeftSlider>
          <H2 textAlign="start" width={603} marginBottom={50}>
            Choose the most appropriate plan that fits your needs
          </H2>

          <Slider {...settings}>
            <CustomSlide />
            <CustomSlide />
            <CustomSlide />
          </Slider>
        </LeftSlider>
        <Image height={465} src={image5} />
      </FourthBlock>
      <FifthBlock>
        <div>
          <Image height={220} marginBottom={35} src={image6} alt="image6" />
          <H2 marginBottom={30}>Any issues or questions?</H2>
          <P width={509}>
            Leave a request with your problem or suggestion and our specialists
            will respond you within 5 minutes and help to solve your problem.
            Have a nice day!
          </P>
        </div>
        <ContactUsBlock>
          <H2 marginTop={30} marginBottom={30}>
            Contact Us
          </H2>
          <FormContactUs>
            <label for="name">
              <InputName type="text" id="name" name="name" placeholder="Name" />
            </label>
            <label for="email">
              <InputEmai
                type="text"
                id="email"
                name="email"
                placeholder="Email"
              />
            </label>
            <label for="message">
              <InputMessage
                type="text"
                id="message"
                name="message"
                placeholder="Message"
              />
            </label>
            <ButtonContactUs>Send</ButtonContactUs>
          </FormContactUs>
        </ContactUsBlock>
      </FifthBlock>
    </MainBlock>
  );
};

export default HomePage;
